/**
 * @file 设备管理 模块接口
 */
import request from '@/utils/request';

/** 设备管理-青鹿盒子 */

/**
 * @desc 列表界面数据初始化（包含年级信息和盒子信息）
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示多少记录
 */
export const getBoxPageInit = (data) => {
  return request({
    url: '/client/box/json/list.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 云终端（青鹿盒子）列表分页查询（只包含盒子信息）
 * @param { Number } pageSize 每页大小
 * @param { Number } pageIndex 当前页
 * @param { Number } gradeId 年级id
 * @param { String } classId 班级id
 */
export const getBoxPageJSON = (data) => {
  return request({
    url: '/client/box/page/json.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 选择年级后刷新班级数据副本
 * @param { String } gradeId 年级id
 */
export const getBoxClassJSON = (data) => {
  return request({
    url: '/json/classListData.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 保存配置信息
 * @param { String } classromId 教室主键id
 * @param { String } syncTime 同步时间（包含同步类型和同步时间），两种同步时间类型分别对应两种格式，具体格式看例子
 * eg:
 * 当syncType为1 的时候：{"syncType":"1","interval":"1"}
 * 当syncType为2的时候：{"syncType":"2","times":[{"starthour":"1","startminute":"1","endhour":"1","endminute":"2"},{"starthour":"2","startminute":"2","endhour":"2","endminute":"3"}]}
 */
export const saveBox = (data) => {
  return request({
    url: '/client/box/save.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑盒子，回显数据
 * @param { String } id 盒子id
 */
export const getBoxOneJSON = (data) => {
  return request({
    url: '/client/box/json/edit.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 删除云终端（盒子）
 * @param { Number } id 终端id
 */
export const deleteBox = (data) => {
  return request({
    url: '/server/registerClass/removeBoxById.do',
    method: 'POST',
    data,
  });
};

/** 设备管理-点阵笔 */

/**
 * @desc 根据学校ID查询铺码方案
 * @param { Number | String } schoolId 学校id
 */
export const getSchoolPrintPlan = (data) => {
  return request({
    url: '/printPlan/getSchoolPrintPlan.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 列表分页查询
 * @param { Number } pageIndex 当前页码
 * @param { Number } pageSize 每页显示多少条记录
 * @param { Number } type 查询类型（0：全部，1：学生点阵笔，2：公共点阵笔 3：教师点阵笔）
 * @param { String } search 查询条件 支持模糊，学员姓名或者点阵笔编号或者教师姓名
 */
export const getPenPageJSON = (data) => {
  return request({
    url: '/dianzhenbi/pagelist/json.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 获取校层webSocket的ip和端口
 */
export const getWebSocketHost = () => {
  return request({
    url: '/dotmatrix/getWebSocketHost.do',
    method: 'GET',
  });
};

/**
 * @desc 编辑点阵笔，保存点阵笔信息
 * @param { String } dzbNo 点阵笔编号
 * @param { Number } type 点阵笔类型（1：个人点阵笔，2：公共点阵笔 3：教师点阵笔）
 * @param { String } classStuNo 班级内部学号（当类型为公共点阵笔时该项不能为空）
 * @param { Number } stuId 班级内部学号（当类型为公共点阵笔时该项不能为空）
 * @param { Number } id 点阵笔id(用于编辑) 0代表新增
 * @param { Number } gradeId 年级id， 当教师点阵笔类型的时候，必填
 */
export const savePen = (data) => {
  return request({
    url: '/dianzhenbi/dzbSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除点阵笔
 * @param {String} ids 点阵笔id，多个id用数组传入
 */
export const deletePen = (data) => {
  return request({
    url: '/dianzhenbi/dzbDelNew.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 根据学号查询学生信息
 * @param { String } search 学号/姓名，输入框的内容
 */
export const getStudentByNoOrName = (data) => {
  return request({
    url: '/dianzhenbi/getStuByNoOrNameNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 根据老师教职工号或者姓名查询列表
 * @param { String } search 老师姓名
 */
export const getTeacherByNoOrName = (data) => {
  return request({
    url: '/dianzhenbi/getTeacherListByKeyword.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 通过websocket接口查询所有点阵笔信息
 * 最开始的时候需要发送40001指令进行登录；
 * web端开始请求获取点阵笔序列号 const WEB_START_GET_DOTMATRIX_SERIAL_NUMBER = 80001；
 * 点阵笔发送点阵笔序列号到web端 const DOTMATRIX_SEND_DOTMATRIX_SERIAL_NUMBER_TO_WEB = 80002； /
 * web端结束请求获取点阵笔序列号 const WEB_STOP_GET_DOTMATRIX_SERIAL_NUMBER = 80003；
 */
export const getPenInfoByWebSocket = (url, data) => {
  return request({
    url: url,
    method: 'GET',
    data,
  });
};

/**
 * @desc 放开限制，根据教师id查询所有任教的年级
 * @param { String } teacherId 教师id
 */
export const getClassLessonGradeListByTeacher = (data) => {
  return request({
    url: '/chonggou/getClassLessonGradeListByTeacher.do',
    method: 'GET',
    data,
  });
};
