<template>
  <el-dialog
    custom-class="ql-dialog "
    title="编辑青鹿盒子"
    :visible.sync="dialogVisible"
    width="568px"
    @close="handleCancel"
  >
    <el-form v-loading="dialogLoading" ref="form" :model="form" label-width="72px">
      <el-form-item label="教室名称" size="small">
        <el-input v-model="form.place" disabled></el-input>
      </el-form-item>
      <el-form-item label="同步时间" size="small">
        <el-radio-group v-model="form.syncType">
          <el-radio :label="ASYNC_TYPE_INTERVAL">每隔几分钟同步一次 </el-radio>
          <el-radio :label="ASYNC_TYPE_PART">每天时段同步</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.syncType === ASYNC_TYPE_INTERVAL" size="small">
        <el-input v-model="form.interval" placeholder="请输入每隔几分钟同步一次"></el-input>
      </el-form-item>
      <el-form-item v-if="form.syncType === ASYNC_TYPE_PART" size="small">
        <div v-for="item in times" :key="item.id" class="flex mg-b-20 relative">
          <el-time-picker
            is-range
            v-model="item.value"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="HH:mm"
            format="HH:mm"
            style="width: 100%"
          >
          </el-time-picker>
          <div v-if="times.length !== 1" class="button-delete" @click="handleDelete(item)">
            <svg class="icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-icon_shanchu"></use>
            </svg>
          </div>
        </div>
        <div class="button-add" @click="handleAdd">
          <svg class="icon svg-icon" aria-hidden="true">
            <use xlink:href="#icon-tianjia"></use>
          </svg>
          添加时段
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleSave" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveBox, getBoxOneJSON } from '@/api/equipment-manage';
import { Form, FormItem, Dialog, Button, Input, RadioGroup, Radio, TimePicker } from 'element-ui';

const ASYNC_TYPE_INTERVAL = '1';
const ASYNC_TYPE_PART = '2';

export default {
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [TimePicker.name]: TimePicker,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    boxId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      form: {
        place: '',
        syncType: ASYNC_TYPE_INTERVAL,
        interval: '',
      },
      times: [
        {
          id: 0,
          value: null,
        },
      ],
      ASYNC_TYPE_INTERVAL,
      ASYNC_TYPE_PART,
    };
  },
  methods: {
    // handle 增加时段
    handleAdd() {
      let id = this.times.length ? this.times[this.times.length - 1].id + 1 : 0;
      this.times.push({
        id: id,
        value: '',
      });
    },
    // handle 删除
    handleDelete(val) {
      this.times = this.times.filter((x) => x !== val);
    },
    handleCancel() {
      this.$emit('close');
    },
    // 校验
    validate() {
      if (this.form.syncType === ASYNC_TYPE_INTERVAL) {
        if (this.form.interval === '') {
          this.$message.error('同步时间不能为空！');
          return false;
        }
        if (Number(this.form.interval) > 10000) {
          this.$message.error('同步时间最大不得超过10000分钟');
          return false;
        }
      }
      if (this.form.syncType === ASYNC_TYPE_PART) {
        if (this.times.length > 20) {
          this.$message.error('同步时段最多不超过20个！');
          return false;
        }
        if (this.times.map((x) => x.value).includes(null)) {
          this.$message.error('同步时间不能为空！');
          return false;
        }
      }
      return true;
    },
    handleSave() {
      if (!this.validate()) {
        return;
      }

      let syncTime = {
        syncType: this.form.syncType,
      };

      if (this.form.syncType === ASYNC_TYPE_INTERVAL) {
        syncTime.interval = this.form.interval;
      }
      if (this.form.syncType === ASYNC_TYPE_PART) {
        syncTime.times = this.times.map((x) => {
          return {
            starthour: x.value[0].split(':')[0],
            startminute: x.value[0].split(':')[1],
            endhour: x.value[1].split(':')[0],
            endminute: x.value[1].split(':')[1],
          };
        });
      }

      saveBox({
        id: this.boxId,
        syncTime: JSON.stringify(syncTime),
      }).then(() => {
        this.$message.success(`编辑成功`);
        this.$emit('success');
      });
    },
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());

          this.dialogLoading = true;

          getBoxOneJSON({
            id: this.boxId,
          })
            .then(({ result }) => {
              this.form.place = result.box.place;
              this.form.syncType = result.box.syncTime?.syncType || ASYNC_TYPE_INTERVAL;

              if (result.box.syncTime?.syncType === ASYNC_TYPE_INTERVAL) {
                this.form.interval = result.box.syncTime.interval;
              }
              if (result.box.syncTime?.syncType === ASYNC_TYPE_PART) {
                this.times = [];

                result.box.syncTime?.times.map((value, index) => {
                  this.times.push({
                    id: index,
                    value: [`${value.starthour}:${value.startminute}`, `${value.endhour}:${value.endminute}`],
                  });
                });
              }
            })
            .finally(() => {
              this.dialogLoading = false;
            });
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.button-delete {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  right: -34px;
  top: -2px;
}
.button-add {
  width: 100px;
  color: $themeColor;
  cursor: pointer;
}
</style>
