/**
 * @desc 青鹿盒子
 */

import { Table, TableColumn, Input, Button, Select, Option } from 'element-ui';
import qlPagination from '@/components/qlPagination';
import basicHeader from '@/components/backStageHeader';
import Dialog from './components/dialog.vue';

import { getBoxPageInit, getBoxPageJSON, deleteBox } from '@/api/equipment-manage';
import { getClassListData } from '@/api/basic';

export default {
  name: 'box',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    qlPagination,
    basicHeader,
    Dialog,
  },
  data() {
    return {
      grades: [
        {
          gradeId: '',
          gradeName: '全部',
        },
      ],
      classes: [
        {
          classId: '',
          className: '全部',
        },
      ],
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        gradeId: '',
        classId: '',
      },
      dialogVisible: false,
      dialogBoxId: '',
    };
  },
  methods: {
    // handle 切换年级
    handleChangeGrade(val) {
      this.table.classId = '';
      this.table.pageIndex = 1;

      getClassListData({
        gradeId: val,
      }).then(({ result }) => {
        this.classes = [
          {
            classId: '',
            className: '全部',
          },
          ...result,
        ];
        this.getBoxPageJSON();
      });
    },
    // handle 切换班级
    handleChangeClass() {
      this.table.pageIndex = 1;
      this.getBoxPageJSON();
    },
    // handle 配置
    handleEdit(val) {
      this.dialogVisible = true;
      this.dialogBoxId = val.id;
    },
    // handle 删除
    handleDelect(val) {
      this.$msgbox
        .confirm('确定删除该云终端吗？删除会影响盒子的使用', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deleteBox({ id: val.id }).then(() => {
            this.$message({
              type: 'success',
              message: '已删除该云终端!',
            });
            this.getBoxPageJSON();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getBoxPageJSON() {
      getBoxPageJSON({
        pageSize: this.table.pageSize,
        pageIndex: this.table.pageIndex,
        gradeId: this.table.gradeId,
        classId: this.table.classId,
      }).then(({ result }) => {
        // console.log(result);
        if (result.data.length === 0 && this.table.pageIndex !== 1) {
          this.table.pageIndex--;
          this.getBoxPageJSON();
          return;
        }

        this.table.list = result.data;
        this.table.total = result.recordCount;
      });
    },
    initPage() {
      getBoxPageInit().then(({ result }) => {
        // console.log(result);
        this.grades = [
          {
            gradeId: '',
            gradeName: '全部',
          },
          ...result.gradeList,
        ];
        this.table.list = result.boxPage.data;
        this.table.total = result.boxPage.recordCount;
      });
    },
  },
  created() {
    this.initPage();
  },
};
